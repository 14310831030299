import React, { Component, Suspense, lazy, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

import UserKyc from "./components/userManagement/UserKyc";
import ViewKyc from "./components/userManagement/ViewKyc";
import Transactionhistory from "./components/transaction/Transactionhistory";
import Bonushistory from "./components/transaction/Bonushistory";
import Withdrawlhistory from "./components/transaction/Withdrawlhistory";
import Deposits from "./components/transaction/Deposits";
import Pages from "./components/Settings/Page";

import axios from "axios";
import Earings from "./dashboard/Earings";
import ComKyc from "./components/userManagement/ComKyc";
import SucWithdraw from "./components/transaction/SucWithdraw";
import RejectKyc from "./components/userManagement/RejectKyc";
import Completed from "./components/challengeManagement/Completed";
import Conflict from "./components/challengeManagement/Conflict";
import Cancelled from "./components/challengeManagement/Cancelled";
import Running from "./components/challengeManagement/Running";
import New from "./components/challengeManagement/New";
import View from "./components/userManagement/View";
import Allhistory from "./components/userManagement/Allhistory";
import UpdatePassword from "./components/adminManagement/UpdatePassword";
import Profile from "./components/adminManagement/Profile";
import Permissions from "./components/agentManagement/Permissions";

import DepositReport from "./components/Reports/DepositReport";
import BonusReport from "./components/Reports/BonusReport";
import WithdrawalReport from "./components/Reports/WithdrawalReport";
import PenaltyReport from "./components/Reports/PenaltyReport";
import { Sitesettings } from "./components/adminManagement/Sitesettings";
// import Notification from "./components/messaging/notification";
import Chat from "./components/messaging/Chat";
import Invoice from "./components/transaction/Invoice";
// import Bonus from './components/transaction/Bonus';
import Bonus from "./components/transaction/bonus";
import { useSelector } from "react-redux";
import Dashboard2 from "./dashboard/Dashboard2";
import PenaltyHistory from "./components/transaction/PenaltyHistory";
import UpiIdFraud from "./components/userManagement/UpiIdFraud";

const PenginGame = lazy(() =>
  import("./components/challengeManagement/PendingGame")
);
const OneHoursGame = lazy(() =>
  import("./components/challengeManagement/OneHoursGame")
);

// Admin Management start
const Alladmin = lazy(() => import("./components/adminManagement/Alladmin"));
const Addadmin = lazy(() => import("./components/adminManagement/Addadmin"));
// Admin Management end

// Game Management start
const Allgames = lazy(() => import("./components/gameManagement/Allgames"));
const Addgame = lazy(() => import("./components/gameManagement/Addgame"));
// Game Management end

// User Management start
const Allusers = lazy(() => import("./components/userManagement/Allusers"));
const Adduser = lazy(() => import("./components/userManagement/Adduser"));
// User Management end

// Agent Management start
const Allagents = lazy(() => import("./components/agentManagement/Allagent"));
const Addagent = lazy(() => import("./components/agentManagement/Addagent"));
// User Management end

// Challenge Management start
const Allchallenges = lazy(() =>
  import("./components/challengeManagement/Allchallenge")
);
const Views = lazy(() => import("./components/challengeManagement/Views"));
// Challenge Management end

// Transaction Management start
const Alldeposits = lazy(() => import("./components/transaction/Deposits"));
const Allwithdrawl = lazy(() => import("./components/transaction/Withdrawl"));
const Penalty = lazy(() => import("./components/transaction/Penalty"));
// const Bonus = lazy(() => import('./components/transaction/Bonus'));
// Transaction Management end

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));

// const Homepage = lazy(() => import('../app2.js/uiComponents/Homepage'));

const AppRoutes = () => {
  const access_token = localStorage.getItem("rkDataram");
  const [dashboard, setDashboard] = useState(false);
  const [earnings, setEarnings] = useState(false);
  const [allAdmins, setAllAdmins] = useState(false);
  const [newAdmin, setNewAdmin] = useState(false);
  const [sitesettings, setsitesettings] = useState(false); //Added by team
  const [allUsers, setAllUsers] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [pendingKyc, setPendingKyc] = useState(false);
  const [completedKyc, setCompletedKyc] = useState(false);
  const [rejectKyc, setRejectKyc] = useState(false);
  const [allChallenges, setAllChallenges] = useState(false);
  const [completedChallenges, setCompletedChallenges] = useState(false);
  const [conflictChallenges, setConflictChallenges] = useState(false);
  const [cancelledChallenges, setCancelledChallenges] = useState(false);
  const [runningChallenges, setRunningChallenges] = useState(false);
  const [newChallenge, setNewChallenge] = useState(false);
  const [penalty, setPenalty] = useState(false);
  const [bonus, setBonus] = useState(false);
  const [depositHistory, setDepositHistory] = useState(false);
  const [bonusHistory, setBonusHistory] = useState(false);
  const [withdrawlHistory, setWithdrawlHistory] = useState(false);
  const [allWithdrawlRequests, setAllWithdrawlRequests] = useState(false);
  const [allDepositRequests, setAllDepositRequests] = useState(false);
  const [pages, setPages] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [challangePage, setChallangePage] = useState(false);

  const [bonusReport, setBonusReport] = useState(false); //Added by team
  const [penaltyReport, setpenaltyReport] = useState(false); //Added by team
  const [withdrawalReport, setwithdrawalReport] = useState(false); //Added by team
  const [depositReport, setdepositReport] = useState(false); //Added by team
  const [legalData, setLegalData] = useState(false); //Added by team
  const getOnUsers = useSelector((state) => state.home.getOnUsers);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    if (getOnUsers) {
      if (getOnUsers.user_type === "Admin") {
        setDashboard(true);
        setEarnings(true);
        setAllAdmins(true);
        setNewAdmin(true);
        setAllUsers(true);
        setsitesettings(true); //Added by team
        setNewUser(true);
        setPendingKyc(true);
        setCompletedKyc(true);
        setRejectKyc(true);
        setAllChallenges(true);
        setCompletedChallenges(true);
        setConflictChallenges(true);
        setCancelledChallenges(true);
        setRunningChallenges(true);
        setNewChallenge(true);
        setPenalty(true);
        setBonus(true);
        setDepositHistory(true);
        setBonusHistory(true);
        setWithdrawlHistory(true);
        setAllWithdrawlRequests(true);
        setAllDepositRequests(true);
        setPages(true);
        setBonusReport(true); //Added by team
        setpenaltyReport(true); //Added by team
        setwithdrawalReport(true); //Added by team
        setdepositReport(true); //Added by team
        setLegalData(true); //Added by team
      }
      if (getOnUsers.user_type === "Agent") {
        setDashboard(getOnUsers.Permissions[0].Status);
        setEarnings(getOnUsers.Permissions[1].Status);
        setAllAdmins(getOnUsers.Permissions[2].Status);
        setNewAdmin(getOnUsers.Permissions[3].Status);
        setAllUsers(getOnUsers.Permissions[4].Status);
        setNewUser(getOnUsers.Permissions[5].Status);
        setPendingKyc(getOnUsers.Permissions[6].Status);
        setCompletedKyc(getOnUsers.Permissions[7].Status);
        setRejectKyc(getOnUsers.Permissions[8].Status);
        setAllChallenges(getOnUsers.Permissions[9].Status);
        setCompletedChallenges(getOnUsers.Permissions[10].Status);
        setConflictChallenges(getOnUsers.Permissions[11].Status);
        setCancelledChallenges(getOnUsers.Permissions[12].Status);
        setRunningChallenges(getOnUsers.Permissions[13].Status);
        setNewChallenge(getOnUsers.Permissions[14].Status);
        setPenalty(getOnUsers.Permissions[15].Status);
        setBonus(getOnUsers.Permissions[16].Status);
        setDepositHistory(getOnUsers.Permissions[17].Status);
        setWithdrawlHistory(getOnUsers.Permissions[18].Status);
        setAllWithdrawlRequests(getOnUsers.Permissions[19].Status);
        setAllDepositRequests(getOnUsers.Permissions[20].Status);
        setPages(getOnUsers.Permissions[21].Status);
        setBonusHistory(getOnUsers.Permissions[22].Status);
        setBonusReport(getOnUsers.Permissions[23].Status); //Added by team
        setwithdrawalReport(getOnUsers.Permissions[24].Status); //Added by team
        setdepositReport(getOnUsers.Permissions[25].Status); //Added by team
        setpenaltyReport(getOnUsers.Permissions[26].Status); //Added by team
        setLegalData(getOnUsers.Permissions[29].Status); //Added by team

        //console.log(getOnUsers.Permissions)
      }
    }
  }, [getOnUsers]);

  if (!access_token) {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* <Route exact path="/login" component={userLogin} /> */}
          <Route exact path="/adminlogin" component={Login} />

          <Redirect to="/adminlogin" />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            {dashboard && (
              <Route exact path="/dashboard" component={Dashboard} />
            )}
            {dashboard && (
              <Route exact path="/dashboard2" component={Dashboard2} />
            )}
            {earnings && <Route exact path="/earinigs" component={Earings} />}

            {/* Admin Routes start */}
            {allAdmins && (
              <Route path="/admin/alladmins" component={Alladmin} />
            )}
            {newAdmin && <Route path="/admin/addadmin" component={Addadmin} />}
            {sitesettings && (
              <Route path="/admin/sitesettings" component={Sitesettings} />
            )}
            <Route path="/admin/update" component={UpdatePassword} />

            <Route path="/admin/profile" component={Profile} />
            <Route path="/agent/permissions/:id" component={Permissions} />

            {/* Admin Routes end */}

            {/* Game Routes start */}

            <Route path="/game/allgames" component={Allgames} />
            <Route path="/game/addgame" component={Addgame} />

            {/* Game Routes end */}

            {/* user Routes start */}
            {allUsers && <Route path="/user/allusers" component={Allusers} />}
            {newUser && <Route path="/user/adduser/:id" component={Adduser} />}

            {newUser && <Route path="/user/adduser" component={Adduser} />}
            {pendingKyc && <Route path="/user/UserKyc" component={UserKyc} />}
            <Route path="/user/view" component={ViewKyc} />
            {completedKyc && <Route path="/user/kyc" component={ComKyc} />}
            {rejectKyc && <Route path="/user/reject" component={RejectKyc} />}
            <Route path="/user/view_user/:id" component={View} />
            <Route path="/user/allhistory/:id" component={Allhistory} />

            {/* user Routes end */}

            {/* Agent Routes start */}

            <Route path="/agent/allagents" component={Allagents} />
            <Route path="/agent/addagent" component={Addagent} />

            {/* Agent Routes end */}

            {/* challenge Routes start */}
            {allChallenges && (
              <Route
                path="/challenge/allchallenges"
                component={Allchallenges}
              />
            )}
            {completedChallenges && (
              <Route
                path="/challenge/completed_challange"
                component={Completed}
              />
            )}
            {conflictChallenges && (
              <Route
                path="/challenge/conflict_challange"
                component={Conflict}
              />
            )}
            {conflictChallenges && (
              <Route
                path="/challenge/pending_challange"
                component={PenginGame}
              />
            )}
            {conflictChallenges && (
              <Route
                path="/challenge/hours_challange"
                component={OneHoursGame}
              />
            )}
            {cancelledChallenges && (
              <Route
                path="/challenge/cancelled_challange"
                component={Cancelled}
              />
            )}
            {runningChallenges && (
              <Route path="/challenge/running_challange" component={Running} />
            )}
            {newChallenge && (
              <Route path="/challenge/new_challange" component={New} />
            )}

            {/* challenge Routes end */}

            {/* Transaction Routes start */}
            {allDepositRequests && (
              <Route path="/transaction/alldeposits" component={Alldeposits} />
            )}
            {allWithdrawlRequests && (
              <Route
                path="/transaction/allwithdrawl"
                component={Allwithdrawl}
              />
            )}
            {penalty && (
              <Route path="/transaction/penalty" component={Penalty} />
            )}
            {penalty && (
              <Route
                path="/transaction/penalty-history"
                component={PenaltyHistory}
              />
            )}
            {bonus && <Route path="/transaction/bonus" component={Bonus} />}
            {depositHistory && (
              <Route
                path="/transaction/transaction_history"
                component={Transactionhistory}
              />
            )}
            {bonusHistory && (
              <Route
                path="/transaction/bonus_history"
                component={Bonushistory}
              />
            )}
            {withdrawlHistory && (
              <Route
                path="/transaction/withdraw_history"
                component={Withdrawlhistory}
              />
            )}
            <Route path="/transaction/withdraw" component={SucWithdraw} />
            <Route path="/user/user-fraud" component={UpiIdFraud} />

            {/* Transaction Routes end */}

            {/* Report Routes start */}
            {/* Added By Team */}
            {bonusReport && (
              <Route path="/Reports/bonusReport" component={BonusReport} />
            )}
            {penaltyReport && (
              <Route path="/Reports/penaltyReport" component={PenaltyReport} />
            )}
            {withdrawalReport && (
              <Route
                path="/Reports/withdrawalReport"
                component={WithdrawalReport}
              />
            )}
            {depositReport && (
              <Route path="/Reports/depositReport" component={DepositReport} />
            )}
            {/* Added By Team */}
            {/* Report Routes Start */}

            <Route path="/basic-ui/buttons" component={Buttons} />
            <Route path="/basic-ui/dropdowns" component={Dropdowns} />
            <Route path="/basic-ui/typography" component={Typography} />

            <Route
              path="/form-Elements/basic-elements"
              component={BasicElements}
            />

            <Route path="/tables/basic-table" component={BasicTable} />

            <Route path="/icons/mdi" component={Mdi} />

            <Route path="/charts/chart-js" component={ChartJs} />

            <Route path="/user-pages/register-1" component={Register1} />

            <Route path="/error-pages/error-404" component={Error404} />
            <Route path="/error-pages/error-500" component={Error500} />
            <Route path="/view/:id" component={Views} />
            {pages && <Route path="/Pages" component={Pages} />}

            {/* Messaging Route */}
            {/* <Route path="/user/notification" component={Notification} /> */}
            {/* <Route path="/user/chat" component={Chat} /> */}
            <Route path="/invoice" component={Invoice} />
          </Switch>
        </Suspense>
      </>
    );
  }
};

export default AppRoutes;
