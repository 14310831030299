import React, { useState, useEffect } from "react";

import axios from "axios";

const Gateway = () => {
  const [RazorPayout, setRazorpayout] = useState(true);
  const [RazorDeposit, setRazorDeposit] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState();
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState();
  const [AccountName, setAccountName] = useState();
  const [decentroPayout, setdecentropayout] = useState(true);
  const [decentroDeposit, setdecentroDeposit] = useState(true);
  const [pinelabDeposit, setpinelabDeposit] = useState(true);
  const [decentroAuto, setdecentroAuto] = useState(false);
  const [settingId, setSettingId] = useState("");
  const [enable_otp, setEnableOtp] = useState();
  const [isCashFree, setIsCashFree] = useState(false);
  const [isPhonePayActive, setIsPhoneActive] = useState(false);
  const [isPayMagicActive, setIsPayMagicActive] = useState(false);
  // console.log(enable_otp)
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    const data = axios.get(baseUrl + "gatewaysettings/data", {}).then((res) => {
      setSettingId(res.data._id ? res.data._id : "");
      setRazorpayout(res.data.RazorPayout);
      setRazorDeposit(res.data.RazorDeposit);
      setRazorpayAuto(res.data.RazorpayAuto);
      setdecentropayout(res.data.decentroPayout);
      setdecentroDeposit(res.data.decentroDeposit);
      setdecentroAuto(res.data.decentroAuto);
      setRazorpayKey(res.data.RazorPayKey);
      setRazorpaysecretKey(res.data.RazorPaySecretKey);
      setAccountName(res.data.AccountName);
      setpinelabDeposit(res.data.pinelabDeposit);
      setEnableOtp(res.data.otp);
      setIsCashFree(res.data.isCashFreeActive);
      setIsPhoneActive(res.data.isPhonePayActive);
      setIsPayMagicActive(res.data.isPayMagicActive);
    });
  }, []);

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("rkDataram");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const response = await axios.post(
      baseUrl + `gatewaysettings`,
      {
        settingId,
        RazorPayout,
        RazorDeposit,
        RazorpayAuto,
        decentroPayout,
        decentroDeposit,
        decentroAuto,
        RazorPayKey,
        RazorPaySecretKey,
        AccountName,
        pinelabDeposit,
        otp: enable_otp,
        isCashFreeActive: isCashFree,
        isPhonePayActive,
        isPayMagicActive,
      },
      {
        headers,
      }
    );
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3">
        Payment Gateway Settings
      </h4>

      {/* <h5 className="text-uppercase font-weight-bold my-3">RazorPay</h5> */}

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        enctype="multipart/form-date"
      >
        <div className="form-row row">
          <div className="col-md-4 mt-1">
            <label htmlFor="buttonrazauto" className="col-5 my-1">
              Razorpay Payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>

          <div className="col-md-4 mt-1">
            <label htmlFor="buttonrazauto" className="col-5 my-1">
              Razorpay Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="col-md-4 mt-1">
            <label htmlFor="buttonrazauto" className="col-4 my-1">
              RazorPay Auto
            </label>
            <select
              className="form-control"
              name=""
              id=""
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row row">
          <div className=" col-md-4 mt-1">
            <label htmlFor="buttonrazauto" className="col-4 my-1">
              RazorPay Key
            </label>
            <input
              className="form-control"
              type="text"
              value={RazorPayKey}
              onChange={(e) => setRazorpayKey(e.target.value)}
            />
          </div>

          <div className="col-md-4 mt-1">
            <label htmlFor="buttonrazauto" className="col-5 my-1">
              RazorPay SecretKey
            </label>
            <input
              className="form-control"
              type="text"
              value={RazorPaySecretKey}
              onChange={(e) => setRazorpaysecretKey(e.target.value)}
            />
          </div>

          <div className="col-md-4 mt-1">
            <label htmlFor="buttonrazauto" className="col-4 my-1">
              Account Name
            </label>
            <input
              className="form-control"
              type="text"
              value={AccountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </div>
        </div>

        {/* <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">Decentro</h5>

            <label htmlFor="buttondecpay" className="col-2 my-1">
              Decentro payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroPayout}
              onChange={(e) => setdecentropayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-2 my-1">
              Decentro Deposit
            </label>
            <select
              className="form-control"
              name=""
              id="" 
              value={decentroDeposit}
              onChange={(e) => setdecentroDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-2 my-1">
              Decentro Auto
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroAuto}
              onChange={(e) => setdecentroAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div> */}

        <div className="form-row  row">
          <div className="col-md-4 mt-1">
            {/* <h5 className="text-uppercase font-weight-bold my-3">Pinelab</h5> */}

            <label htmlFor="buttondecdep" className="col-4 mt-1 ">
              Pinelab Deposit
            </label>
            <select
              className="form-control"
              name=""
              id=""
              value={pinelabDeposit}
              onChange={(e) => setpinelabDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="col-md-4 mt-1">
            <label htmlFor="buttondecdep" className="col-4 mt-1 ">
              Enable Otp
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={enable_otp}
              onChange={(e) => setEnableOtp(e.target.value)}
            >
              <option value="1">Enable</option>
              <option value="0">Disable</option>
            </select>
          </div>
          <div className="col-md-4 mt-1">
            <label htmlFor="buttondecdep" className="col-4 mt-1 ">
              Is Cash Free
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={isCashFree}
              onChange={(e) => setIsCashFree(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>
          <div className="col-md-4 mt-1">
            <label htmlFor="buttondecdep" className="col-4 mt-1 ">
              Phonepe PG
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={isPhonePayActive}
              onChange={(e) => setIsPhoneActive(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>
          <div className="col-md-4 mt-1">
            <label htmlFor="buttondecdep" className="col-4 mt-1 ">
              Pay Magic
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={isPayMagicActive}
              onChange={(e) => setIsPayMagicActive(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-dark">
              submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;
