import axios from "axios";
import React, { useState } from "react";

const DashDeposit = () => {
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalRejected, setTotalRejected] = useState(0);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const totalDepositfunc = () => {
    const access_token = localStorage.getItem("rkDataram");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `total/deposit`, { headers }).then((res) => {
      setTotalDeposit(res.data);
    });
  };

  const totalRejectedfunc = () => {
    const access_token = localStorage.getItem("rkDataram");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `count/rejected/deposit`, { headers }).then((res) => {
      setTotalRejected(res.data);
    });
  };

  const totalPendingfunc = () => {
    const access_token = localStorage.getItem("rkDataram");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `count/new/deposit`, { headers }).then((res) => {
      setTotalPending(res.data);
    });
  };

  return (
    <>
      <div className="">
        <div className="row">
          <h2 className="box-desing-h2">Today Deposit Details :-</h2>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Total Deposit</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Cancel Deposit</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Success Deposit</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Pending Deposit</h3>
              <span>4099878</span>
            </div>
          </div>
        </div>
        <div className="row">
          <h2 className="box-desing-h2">Total Deposit Details :-</h2>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Total Deposit</h3>
              <span>{totalDeposit && totalDeposit.count}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Reject Deposit</h3>
              <span>{totalRejected && totalRejected.count}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Success Deposit</h3>
              <span>{totalDeposit && totalDeposit.data}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Pending Deposit</h3>
              <span>{totalPending && totalPending.count}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashDeposit;
