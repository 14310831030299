import axios from "axios";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";

const CSV = (props) => {
  const [Data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const csvInstance = useRef();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const response = props.response;
  const headers = props.headers;
  const data = props.data;

  let postArray = {};
  if (props.extra) {
    postArray = props.extra;
  }

  const getapidata = () => {
    setloading(true);
    let access_token = localStorage.getItem("rkDataram");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `${props.link}`, { headers })
      .then((res) => {
        setData(res.data);
        setTimeout(() => {
          csvInstance.current.link.click();
          setloading(false);
        }, 10);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <button
        disabled={loading}
        className={`btn btn-danger mb-3 ${loading && `disabled`}`}
        onClick={async () => {
          if (loading) {
            return;
          }
          getapidata();
        }}
      >
        {loading ? "Loading..." : `Download ${props.type} History`}
      </button>
      {Data ? (
        <CSVLink
          {...props}
          data={Data}
          filename={`${props.name}.csv`}
          ref={csvInstance}
          headers={headers}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CSV;
